<template>
    <div class="ifunction-oa-block px-4">
        <div class="sub-title">
            <v-row no-gutters justify="center" align="center" class="mt-2">
                iFunction LINE OA 加入 QRCODE
            </v-row>
        </div>
        <template v-if="iFunctionId">
            <v-row no-gutters justify="center" align="center" class="mt-2">
                <v-col justify="center" align="center">
                    <div>
                        此身份已綁定iFunction，如要解除綁定請聯絡羅氏相關人員。
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row justify="center" class="my-10">
                <v-col cols="auto">
                    <qrcode-vue :value="url" :size="size" level="L" class="qrcode"></qrcode-vue>
                </v-col>
            </v-row>
            <v-row v-if="url" no-gutters justify="center" align="center" class="mt-2">
                <v-text-field label="URL" :value="url" readonly class="display-l pr-2"></v-text-field>
                <v-btn @click="copyUrl">copy</v-btn>
            </v-row>
        </template>
        <v-alert width="500px" class="mx-auto" type="error" dismissible :value="errorMessage" close-icon
            :closable="false">
            {{ errorMessage }}
            <v-btn class="ml-4" size="small" variant="tonal" color="grey" @click="init">重試</v-btn>
        </v-alert>
        <v-snackbar v-model="snackbar" multi-line>
            <template v-slot:actions>
                <v-btn color="red" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import API from '../models/api';
import QrcodeVue from 'qrcode.vue'

export default {
    components: {
        QrcodeVue,
    },
    props: {
        hcpId: String,
        iFunctionId: String,
    },
    data: () => ({
        url: '',
        size: 250,
        errorMessage: null,
        snackbar: false,
    }),
    methods: {
        async init() {
            this.errorMessage = null;
            try {
                const { data } = await API.iFunction.generateQRcode(this.hcpId);
                this.url = data.qrcodeUrl;
            } catch (e) {
                this.errorMessage = 'QRCode產生發生錯誤'
            }
        },
        copyUrl() {
            this.$emit('copy', { name: 'URL', url: this.url });
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style lang="scss">
.ifunction-oa-block {
    background-color: #f1f1f1;
    padding-top: 40px;
    padding-bottom: 40px;
}
.qrcode {
    background-color: white;
    padding: 16px;
}
</style>
