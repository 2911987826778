var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"article,article,article"}}):[_c('div',{staticClass:"profile-block"},[_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align":"center"}},[_c('v-text-field',{staticClass:"display-m px-3",attrs:{"label":"姓名","value":_vm.name,"readonly":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-combobox',{staticClass:"display-m px-3",attrs:{"value":_vm.hcos,"items":_vm.hco,"label":"醫院","item-text":"name","readonly":"","multiple":"","chips":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-combobox',{staticClass:"display-m px-3",attrs:{"value":_vm.specialties,"items":_vm.specialty,"label":"科別","item-text":"name","readonly":"","multiple":"","chips":""}})],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"consent-block remote-consent"},[_c('div',{staticClass:"sub-title"},[_c('v-row',{staticClass:"mt-2 text",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" 羅氏大藥廠各數位管道通用 Consent ")])],1),(_vm.consentUrl)?_c('div',[(_vm.verificationCode)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-dialog',{attrs:{"content-class":"remote-consent-dialog","width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v("複製 Consent 連結")])]}}],null,false,3404807576),model:{value:(_vm.remoteConsentDialog),callback:function ($$v) {_vm.remoteConsentDialog=$$v},expression:"remoteConsentDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" 注意 ")]),_c('v-card-text',[_vm._v(" 我知悉並確認此連結只提供給指定HCP，以進行【羅氏大藥廠數位管道】個資同意書的簽署，我並未擅自或提供給第三人代簽。如有違反，願受懲戒之處分。 ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.remoteConsentDialog = false}}},[_vm._v(" 關閉 ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.copyConsentUrl(
                                        'Consent 連結',
                                        _vm.consentUrl,
                                        '.remote-consent-dialog'
                                    )}}},[_vm._v(" 確認 ")])],1)],1)],1)],1):_vm._e()],1):_c('div',[(_vm.verificationCode)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" 已簽過 Remote Consent ")]):_vm._e()],1)]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"consent-block fmi-consent"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"fim-logo",attrs:{"src":require("@/assets/FMI_logo-white.png"),"alt":""}}),_c('v-row',{staticClass:"mt-2 text",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" Foundation 全方位癌症基因檢測醫師知情暨個資權同意書 ")])],1),(_vm.fmiUrl)?_c('div',[(_vm.fmiVerificationCode)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-dialog',{attrs:{"content-class":"fmi-consent-dialog","width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v("複製 RFM 同意書連結")])]}}],null,false,1024131412),model:{value:(_vm.fmiConsentDialog),callback:function ($$v) {_vm.fmiConsentDialog=$$v},expression:"fmiConsentDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" 注意 ")]),_c('v-card-text',[_vm._v(" 我知悉並確認此連結只提供給指定HCP，以進行【羅氏大藥廠 Foundmation Medicine】個資同意書的簽署，我並未擅自或提供給第三人代簽。如有違反，願受懲戒之處分。 ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.fmiConsentDialog = false}}},[_vm._v(" 關閉 ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.copyConsentUrl(
                                        'RFM 同意書連結',
                                        _vm.fmiUrl,
                                        '.fmi-consent-dialog'
                                    )}}},[_vm._v(" 確認 ")])],1)],1)],1)],1):_vm._e()],1):_c('div',[(_vm.fmiVerificationCode)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" 已簽過 FMI Consent ")]):_vm._e()],1)]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"line-oa-block px-4"},[_c('div',{staticClass:"sub-title"},[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" 羅氏大藥廠官方 LINE OA 加入 QRCODE ")])],1),(!_vm.profile_id)?_c('v-row',{staticClass:"my-10",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('qrcode-vue',{staticClass:"qrcode",attrs:{"value":_vm.url,"size":_vm.size,"level":"L"}})],1)],1):_vm._e(),(!_vm.profile_id && _vm.code)?_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-text-field',{staticClass:"display-s pr-2",attrs:{"label":"認證碼","value":_vm.code,"readonly":""}}),_c('v-btn',{on:{"click":_vm.copyCode}},[_vm._v("copy")])],1):_vm._e(),(!_vm.profile_id && _vm.url)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-text-field',{staticClass:"display-l pr-2",attrs:{"label":"URL","value":_vm.url,"readonly":""}}),_c('v-btn',{on:{"click":_vm.copyUrl}},[_vm._v("copy")])],1):_vm._e(),(_vm.profile_id)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('div',[_vm._v(" 此身份已綁定LINE，如要解除綁定請聯絡羅氏相關人員。 ")])])],1):_vm._e()],1),_c('v-divider',{staticClass:"my-3"}),_c('i-function-qrcode',{attrs:{"hcpId":_vm.hcpId,"iFunctionId":_vm.iFunctionId},on:{"copy":_vm.onCopy}})],_c('v-snackbar',{attrs:{"top":true,"color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.copiedTarget)+" 已複製至您的剪貼簿 ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }