<template>
    <v-container>
        <v-skeleton-loader v-if="loading" class="mx-auto" max-width="300"
            type="article,article,article"></v-skeleton-loader>
        <template v-else>
            <div class="profile-block">
                <v-row justify="center" align="center" class="mt-7">
                    <v-text-field label="姓名" :value="name" readonly class="display-m px-3"></v-text-field>
                </v-row>
                <v-row justify="center">
                    <v-combobox :value="hcos" :items="hco" label="醫院" item-text="name" readonly multiple chips
                        class="display-m px-3"></v-combobox>
                </v-row>
                <v-row justify="center">
                    <v-combobox :value="specialties" :items="specialty" label="科別" item-text="name" readonly multiple chips
                        class="display-m px-3"></v-combobox>
                </v-row>
            </div>
            <v-divider class="my-3" />
            <div class="consent-block remote-consent">
                <div class="sub-title">
                    <v-row no-gutters justify="center" align="center" class="mt-2 text">
                        羅氏大藥廠各數位管道通用 Consent
                    </v-row>
                </div>
                <div v-if="consentUrl">
                    <v-row v-if="verificationCode" no-gutters justify="center" align="center" class="mt-2">
                        <v-dialog content-class="remote-consent-dialog" v-model="remoteConsentDialog" width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on">複製 Consent 連結</v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                    注意
                                </v-card-title>
                                <v-card-text>
                                    我知悉並確認此連結只提供給指定HCP，以進行【羅氏大藥廠數位管道】個資同意書的簽署，我並未擅自或提供給第三人代簽。如有違反，願受懲戒之處分。
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" @click="remoteConsentDialog = false">
                                        關閉
                                    </v-btn>
                                    <v-btn color="primary" @click="copyConsentUrl(
                                            'Consent 連結',
                                            consentUrl,
                                            '.remote-consent-dialog'
                                        )
                                        ">
                                        確認
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </div>
                <div v-else>
                    <v-row v-if="verificationCode" no-gutters justify="center" align="center" class="mt-2">
                        已簽過 Remote Consent
                    </v-row>
                </div>
            </div>
            <v-divider class="my-3" />
            <div class="consent-block fmi-consent">
                <div class="sub-title">
                    <img class="fim-logo" src="@/assets/FMI_logo-white.png" alt="" />
                    <v-row no-gutters justify="center" align="center" class="mt-2 text">
                        Foundation 全方位癌症基因檢測醫師知情暨個資權同意書
                    </v-row>
                </div>
                <div v-if="fmiUrl">
                    <v-row v-if="fmiVerificationCode" no-gutters justify="center" align="center" class="mt-2">
                        <v-dialog content-class="fmi-consent-dialog" v-model="fmiConsentDialog" width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on">複製 RFM 同意書連結</v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                    注意
                                </v-card-title>
                                <v-card-text>
                                    我知悉並確認此連結只提供給指定HCP，以進行【羅氏大藥廠
                                    Foundmation
                                    Medicine】個資同意書的簽署，我並未擅自或提供給第三人代簽。如有違反，願受懲戒之處分。
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" @click="fmiConsentDialog = false">
                                        關閉
                                    </v-btn>
                                    <v-btn color="primary" @click="copyConsentUrl(
                                            'RFM 同意書連結',
                                            fmiUrl,
                                            '.fmi-consent-dialog'
                                        )
                                        ">
                                        確認
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </div>
                <div v-else>
                    <v-row v-if="fmiVerificationCode" no-gutters justify="center" align="center" class="mt-2">
                        已簽過 FMI Consent
                    </v-row>
                </div>
            </div>
            <v-divider class="my-3" />
            <div class="line-oa-block px-4">
                <div class="sub-title">
                    <v-row no-gutters justify="center" align="center" class="mt-2">
                        羅氏大藥廠官方 LINE OA 加入 QRCODE
                    </v-row>
                </div>
                <v-row v-if="!profile_id" justify="center" class="my-10">
                    <v-col cols="auto">
                        <qrcode-vue :value="url" :size="size" level="L" class="qrcode"></qrcode-vue>
                    </v-col>
                </v-row>
                <v-row v-if="!profile_id && code" no-gutters justify="center" align="center">
                    <v-text-field label="認證碼" :value="code" readonly class="display-s pr-2"></v-text-field>
                    <v-btn @click="copyCode">copy</v-btn>
                </v-row>
                <v-row v-if="!profile_id && url" no-gutters justify="center" align="center" class="mt-2">
                    <v-text-field label="URL" :value="url" readonly class="display-l pr-2"></v-text-field>
                    <v-btn @click="copyUrl">copy</v-btn>
                </v-row>
                <v-row v-if="profile_id" no-gutters justify="center" align="center" class="mt-2">
                    <v-col justify="center" align="center">
                        <div>
                            此身份已綁定LINE，如要解除綁定請聯絡羅氏相關人員。
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider class="my-3" />
            <i-function-qrcode :hcpId="hcpId" :iFunctionId="iFunctionId" @copy="onCopy" />
        </template>
        <v-snackbar :top="true" v-model="snackbar" color="success">
            {{ copiedTarget }} 已複製至您的剪貼簿
        </v-snackbar>

    </v-container>
</template>

<script>
import API from '@/models/api';
import { mapState } from 'vuex';
import iFunctionQrcode from '../components/iFunctionQrcode.vue';
import QrcodeVue from 'qrcode.vue'

export default {
    components: {
        iFunctionQrcode,
        QrcodeVue,
    },
    data() {
        return {
            hco: [],
            hcos: [],
            name: '',
            profile_id: null,
            iFunctionId: null,
            product_disease_ta: [],
            product_disease_tas: [],
            specialty: [],
            specialties: [],
            permission: [],
            permissions: [],
            code: '',
            verificationCode: '',

            // snackbar
            copiedTarget: '',
            snackbar: false,

            // loading
            loading: true,

            // qr-code
            url: '',
            size: 250,
            // dialog
            remoteConsentDialog: false,
            fmiConsentDialog: false
        };
    },
    created() {
        if (!this.$route.query.id) {
            this.$router.push('/');
        }
        this.fetchData();
    },
    computed: {
        ...mapState({
            verifyUrl: 'verifyUrl'
        }),
        consentUrl() {
            if (this.verificationCode != 'na') {
                return process.env.VUE_APP_CONSENT_URL + this.verificationCode;
            }
            return false;
        },
        fmiUrl() {
            if (this.fmiVerificationCode != 'na') {
                return (
                    process.env.VUE_APP_FMI_CONSENT_URL +
                    this.fmiVerificationCode
                );
            }
            return false;
        },
        hcpId() {
            return this.$route.query.id;
        },
    },
    methods: {
        async fetchData() {
            try {
                const res = await API.Hcp.data(this.hcpId);
                this.hco = res.data.hco;
                this.hco.forEach(item => {
                    this.hcos.push(item.hco_id.name);
                });
                this.name = res.data.name;
                this.profile_id = res.data.profile_id;
                this.iFunctionId = res.data.ifunction_platform_id;
                this.specialty = res.data.specialty;
                this.specialty.forEach(item => {
                    this.specialties.push(item.specialty_id.name);
                });
                this.product_disease_ta = res.data.product_disease_ta;
                this.product_disease_ta.forEach(item => {
                    this.product_disease_tas.push(
                        item.product_disease_ta_id.indication
                    );
                });
                this.permission = res.data.permissions;
                this.permission.forEach(item => {
                    this.permissions.push(item.permission.name);
                });

                this.code = res.data.code;
                this.url = `${this.verifyUrl}${this.code}`;
                this.verificationCode = res.data.verification_code;
                this.fmiVerificationCode = res.data.fmi_verification_code;
                this.loading = false;
            } catch (e) {
                console.log(e);
            }
        },
        copyCode() {
            this.$copyText(this.code).then(() => {
                this.copiedTarget = '驗證碼';
                this.snackbar = true;
            });
        },
        copyUrl() {
            this.$copyText(this.url).then(() => {
                this.copiedTarget = 'URL';
                this.snackbar = true;
            });
        },
        copyConsentUrl(name, url, el) {
            let container = document.querySelector(el);
            this.$copyText(url, container).then(() => {
                this.copiedTarget = name;
                this.snackbar = true;
                this.remoteConsentDialog = false;
                this.fmiConsentDialog = false;
            });
        },
        onCopy({ name, url }) {
            this.$copyText(url).then(() => {
                this.copiedTarget = name;
                this.snackbar = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.display {
    &-s {
        max-width: 150px;
    }

    &-m {
        max-width: 370px;
    }

    &-l {
        max-width: 500px;
    }
}

.sub-title, /deep/.sub-title {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;

    .fim-logo {
        width: 253px;
    }

    .text {
        text-align: center;
    }
}

.consent-block {
    padding: 50px 7px;
}

.consent-block.remote-consent {
    background-color: #f1f1f1;
}

.consent-block.fmi-consent {
    background-color: #ff4c00;
    color: white;
}

.line-oa-block {
    .sub-title {
        margin-top: 40px;
    }
}
</style>
